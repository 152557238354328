@import "~dompack/browserfix/reset.css";
@import './rtd.css';
.wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  display: flex;
}

.wh-form__fieldgroup {
  align-items: baseline;
}

.wh-form__label, .wh-form__optiondata,
.wh-form__optionlabel,
.wh-form__subfieldlabel {
  display: inline-block;
}

.wh-form__label {
  flex-shrink: 0;
}

.wh-form__fields {
  flex: 1 0 0px;
}

.wh-form__fieldline {
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
}

.wh-form__fieldline:not(.wh-form__fieldline--hidden) {
  display: inline-flex;
}

.wh-form__fieldline--nowrap:not(.wh-form__fieldline--hasinfotext) {
  flex-wrap: nowrap;
}

.wh-form__fieldgroup--horizontal .wh-form__fields {
  display: flex;
  flex-wrap: wrap;
}
.wh-form__fieldgroup--horizontal .wh-form__fieldline {
  flex: none;
  width: auto;
  max-width: 100%;
}

.wh-form__infotextwrapper {
  flex: 0 0 100%;
}

.wh-form__optiondata {
  display: inline-flex;
  width: 100%;
  align-items: baseline;
  flex-grow: 1;
  flex-wrap: wrap;
}
.wh-form__optiondata--vertical {
  width: 0;
}

.wh-form__subfield {
  display: flex;
  flex-grow: 1;
  align-items: baseline;
}

.wh-form__fieldline > input[type=checkbox],
.wh-form__fieldline > input[type=radio] {
  flex-shrink: 0;
}

.wh-form__fieldline > textarea {
  resize: vertical;
}

.wh-form__label {
  min-width: 120px;
}

.wh-form__fieldgroup {
  padding: 0 0 8px 0;
}

.wh-form__fieldgroup p.question {
  padding: 0;
}

button.wh-form__button.wh-form__button--submit {
  font-size: 100%;
  border-radius: 8px;
}

@media (max-width: 550px) {
  .wh-form__fieldgroup {
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  .wh-form__fields {
    width: 100%;
  }
}
.slideshow {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.slideshow:after {
  display: block;
  content: "";
  padding-bottom: 27%;
}
.slideshow__slide {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  width: 100%;
}
.slideshow .slideshow__slide {
  opacity: 1;
  transition-property: opacity;
}
.slideshow .slideshow__slide.slideshow--slidein {
  opacity: 0;
}
.slideshow .slideshow__slide.slideshow--slideout {
  opacity: 0;
}
.slideshow .slideshow__slide img {
  width: 100%;
  height: auto;
}

html {
  background-color: #ffffff;
  color: #3B3B3B;
  font: 13px/1.5em Arial, Sans-Serif;
}

a {
  color: inherit;
  text-decoration: none;
  transition: color 0.2s;
}

a:hover {
  color: #8d7354;
}

p {
  margin: 0px;
  padding: 0 0 10px 0;
}

#mobilelogoholder {
  display: none;
}

header {
  position: relative;
  width: 100%;
}

.righthr {
  height: 1px;
  background: #5c5b5b;
  margin-bottom: 20px;
  margin-top: 20px;
}

#menubar {
  width: 100%;
  background: #b0b0b0;
  background: -ms-linear-gradient(top, #ddd9da 0%, #b0b0b0 100%); /* IE10+ */
  background: linear-gradient(to bottom, #ddd9da 0%, #b0b0b0 100%); /* W3C */
  border-top: 1px solid #8b8b8b;
  border-bottom: 1px solid #8b8b8b;
  height: 52px;
}

#menubar nav {
  padding-left: 279px;
}

#menubar nav li {
  list-style: none;
  display: inline-block;
  font: 16px/50px "Helvetica Neue", Helvetica, Arial;
  margin: 0 20px;
}

#menubar nav li a {
  color: #3b3b4e;
}

#menubar nav li a:hover {
  color: #8d7354;
}

#contentholder {
  width: 100%;
}

#contentcenter {
  position: relative;
  background: #ffffff;
  padding: 0 0 41px;
  /*padding:20px 12px 41px 279px;*/
}

#contentcenter > * {
  float: left;
}

#contentcenter:after {
  content: "";
  display: block;
  clear: both;
}

#logoholder {
  position: absolute;
  top: -163px;
  left: -15px;
}

main {
  max-width: 760px;
  margin-left: 13px;
  margin-top: 20px;
  width: calc(100% - 80px);
}

main ul {
  padding: 0 10px 10px 25px;
  margin: 0;
}

main:after {
  content: "";
  display: block;
  clear: both;
}

.wh-imagegallery {
  max-height: 400px;
}

.wh-imagegallery-preview .wh-imagegallery-scroller-content > .thumb {
  width: 200px;
}

.wh-imagegallery-preview .wh-imagegallery-scroller-content > .thumb img {
  max-width: none;
}

.wh-imagegallery-preview .infobar {
  background-color: #8d7354;
  color: #fff;
  height: 30px;
  text-align: center;
  line-height: 30px;
  white-space: nowrap;
}

.wh-imagegallery-preview .infobar .counter,
.wh-imagegallery-preview .infobar .info {
  display: inline-block;
}

.wh-imagegallery-preview .wh-slideshow-item {
  margin-top: 30px;
}

.wh-imagegallery-grid .wh-slideshow {
  background-color: transparent;
}

.wh-imagegallery .previous,
.wh-imagegallery-preview .previous,
.wh-imagegallery .next,
.wh-imagegallery-preview .next {
  background-color: rgba(0, 0, 0, 0.4);
  padding: 12px 20px;
  width: 65px;
  height: 65px;
  border-radius: 10px;
}

img {
  border-style: none;
}

img.centeredimage {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

img.leftimage {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}

img.rightimage {
  float: right;
  margin-left: 10px;
  margin-bottom: 10px;
}

img.camera {
  width: 526px;
}

footer {
  width: 100%;
  background: #8d7354;
  min-height: 120px;
  padding-top: 17px;
  color: #ffffff;
  font: 13px "Lucida Sans Unicode", "Lucida Grande", sans-serif;
}

footer a:hover {
  color: #ffffff;
  text-decoration: underline;
}

.centerit {
  position: relative;
  width: 818px;
  margin: 0 auto;
}

table {
  padding-left: 10px;
  padding-right: 10px;
}

.whwp-question {
  margin-bottom: 10px;
}

.whwp-title {
  font: italic 110% "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  color: #000000;
  margin: 10px 0 10px 0;
}

.wh-form__fieldgroup--required .wh-form__label:after {
  content: "*";
}

@media (max-width: 817px) {
  #mobilelogoholder {
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
  }
  #mobilelogoholder img {
    max-width: 50%;
    width: auto;
    height: auto;
  }
  #logoholder {
    display: none;
  }
  #contentcenter > * {
    float: none;
  }
  #menubar {
    height: auto;
  }
  #menubar nav {
    padding: 0 5px;
  }
  .centerit {
    width: auto;
  }
  main {
    width: auto;
  }
  main img {
    max-width: 100%;
    width: auto;
    height: auto;
  }
  footer {
    padding: 5px;
  }
  h1 + p img {
    max-width: 100px !important;
    height: auto;
  }
}