html
{
  font: 10pt Arial, Sans-Serif;
}

h2
{
  margin: 0;
  padding: 10px 0 0 0;
  font: italic 110% "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  color: #000000;
}
h1
{
  font: 18px "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  color: #000000;
  margin-bottom: 8px;
}
p
{
  margin: 0px;
  padding: 0 0 16px 0;
}
.wh-rtd__tablecell {
  vertical-align: middle;
}
