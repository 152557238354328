.pure-menu {
  background-color: #8d7354;
}

.pure-menu-link, .pure-menu-disabled, .pure-menu-heading {
  padding: 0.6em 3em;
}

.pure-menu-active > .pure-menu-link, .pure-menu-link:hover, .pure-menu-link:focus {
  background-color: #8d7354;
}

.pure-menu-link {
  color: #ffffff;
}

.pure-menu-selected .pure-menu-link, .pure-menu-selected .pure-menu-link:visited {
  color: #c8bca6;
}

a.pure-menu-link:hover {
  color: #c8bca6;
}

.header__menuholder {
  text-align: center;
}

.pure-menu-link:visited {
  color: #ffffff;
}

.pure-menu-link {
  font-size: 13px;
  letter-spacing: 2px;
  display: inline-block;
}

.pure-menu-children {
  padding-top: 13px;
  background-color: transparent;
}

.pure-menu-children li {
  background-color: #8d7354;
  text-align: left;
  padding: 5px 65px 5px 2px;
}

.pure-menu-children li a {
  font-size: 12px;
  letter-spacing: 1px;
}

@media (max-width: 780px) {
  .pure-menu-horizontal .pure-menu-list {
    display: block;
  }
  .pure-menu-horizontal .pure-menu-item {
    display: block;
  }
}